<template>
  <b-card body-class="p-0">
    <b-alert
      v-if="loading"
      show
      variant="warning"
      class="mt-1 px-2 py-1 d-flex-center gap-2 justify-content-start"
    >
      <b-spinner
        variant="warning"
        tag="span"
      />
      <span>
        {{ $t('loading') }}
      </span>
    </b-alert>
    <div v-else-if="isEmpty(serviceOptions)">
      <b-alert
        show
        variant="warning"
        class="mt-1"
      >
        <div class="alert-body text-center text-warning">
          <feather-icon
            class="mr-25"
            icon="FrownIcon"
            size="25"
          />
          <span>
            {{ $t('flight.noAncillary') }}
          </span>
        </div>
      </b-alert>
    </div>

    <b-card
      v-else
      class="mb-0 border-0"
      body-class="p-0"
    >
      <!-- NOTE: VN1A bỏ qua các hành trình có điểm dừng  https://discord.com/channels/1054696448110903327/1315896234463985694/1318860064261537793 -->
      <div v-if="!isDevelopment && selectedTrip.some(trip => ['VN1A'].includes(trip?.source) && trip?.segments?.length > 1)">
        <b-alert
          show
          variant="warning"
        >
          <div class="alert-body text-center text-warning">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
              size="25"
            />
            <span class="fw-700">
              Nếu có nhu cầu mua dịch vụ bổ sung cho chuyến bay có điểm dừng, vui lòng liên hệ booker hỗ trợ!
            </span>
          </div>
        </b-alert>
      </div>

      <div v-if="selectedTrip.some(trip => ['AK'].includes(trip?.source))">
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body text-center text-danger">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
              size="25"
            />
            <span class="fw-700">
              Lưu ý: Thêm hành lý tại thời điểm này sẽ rẻ hơn sau khi đã giữ chỗ. Sau khi giữ chỗ phí hành lý sẽ đắt hơn đáng kể.
            </span>
          </div>
        </b-alert>
      </div>

      <b-row>
        <!-- ANCHOR - Chặng bay -->
        <b-col md="5">
          <b-form-group
            :label="$t('flight.flightSegment')"
            label-for="chang-bay"
            class="pb-25 mb-0"
          >
            <v-select
              v-model="segment"
              input-id="chang-bay"
              label="segmentData"
              class="select-size-sm mr-1 w-100"
              :clearable="false"
              :options="segmentOptions"
              :placeholder="$t('flight.placeholderSelect')"
            >
              <template #option="{ segmentData }">
                <div class="fw-700">
                  <span>
                    {{ $t('flight.priceReport.airline') }}:
                    {{ segmentData.source ? segmentData.source : segmentData.airline }} |
                  </span>
                  <span>
                    {{ segmentData ? $t('flight.segment'): $t('flight.itinerary') }}:
                    {{ ['TR', 'JQ'].includes(segmentData?.source) ? segmentData.itemFlight : `${segmentData.departure.IATACode}-${ segmentData.arrival.IATACode}` }}
                    ({{ convertISODateTime(segmentData.departure.at).dayAndMonth }})
                  </span>
                </div>
              </template>

              <template #selected-option="{segmentData}">
                <div class="font-small-4 fw-700">
                  <span>
                    {{ segmentData.source ? segmentData.source : segmentData.airline }}
                  </span>
                  |
                  <span>
                    {{ ['TR', 'JQ'].includes(segmentData?.source) ? segmentData.itemFlight : `${segmentData.departure.IATACode} - ${segmentData.arrival.IATACode}` }}
                    ({{ convertISODateTime(segmentData.departure.at).dayAndMonth }})
                  </span>
                </div>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="my-50">

      <!-- ANCHOR - Passenger -->
      <div v-if="passengersData">
        <b-row
          v-for="(passenger, index) in passengersData"
          :key="index"
          class="w-100 m-0"
        >
          <b-col
            class="px-0 mt-1 mt-md-0 pb-25 pb-md-1 pt-25"
            md="4"
          >
            {{ $t('flight.passenger') }}:
            <span class="font-weight-bolder">
              {{
                passenger.firstName && passenger.lastName
                  ? `${passenger.title} ${passenger.lastName} ${passenger.firstName}`
                  : `${$t(`reservation.${passenger.paxType}`)} #${index + 1}`
              }}
            </span>
          </b-col>

          <!-- ANCHOR - Gói dịch vụ -->
          <b-col
            cols="12"
            md="5"
            class="px-0"
          >
            <v-select
              :id="`service-packages-${index}`"
              v-model="passenger.servicePackage"
              class="ssrs_package_select select-size-sm w-100"
              label="code"
              :filter-by="filterBy"
              :placeholder="$t('flight.servicePlaceholder')"
              :disabled="disableServicePackage"
              :options="serviceListOptions"
              @input="(val) => setDefaultPassengerAmount(val, passenger)"
            >
              <template #option="data">
                <div
                  class="d-flex-between"
                  :class="isMobileView ? 'font-small-1' : 'font-small-4'"
                >
                  <span
                    class="mr-75"
                    :class="resolveVariantByBagg(data)"
                  >
                    {{ resolveSsrBagsName(data) }}
                  </span>
                  <span class="font-weight-bolder text-warning">
                    {{
                      formatCurrency(resolvePriceService(data, passenger.paxType))
                    }}
                  </span>
                </div>
              </template>
              <template #selected-option="data">
                <div :class="`d-flex-between font-weight-bolder ${isMobileView ? 'font-small-1' : 'font-small-4'}`">
                  <span>
                    {{ resolveSsrBagsName(data) }}
                  </span>
                  <span class="text-warning ml-50">
                    {{ formatCurrency(resolvePriceService(data, passenger.paxType)) }}
                  </span>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- ANCHOR - Số lượng mua -->
          <b-col
            cols="4"
            md="2"
            class="mt-50 mt-md-0 px-0 px-md-1"
          >
            <v-select
              :id="`service-packages-amount-${index}`"
              v-model="passenger.serviceAmount"
              class="select-size-sm w-100"
              label="text"
              :placeholder="$t('flight.Amount')"
              :clearable="false"
              :disabled="disableServicePackage || (segment && ['VN1A'].includes(segment.source)) || !passenger.servicePackage"
              :options="amountOptions"
            >
              <template #option="{text}">
                <span class="font-small-3">
                  {{ text }} {{ $t('flight.package') }}
                </span>
              </template>
              <template #selected-option="{text}">
                <span class="font-small-3 pl-50">
                  {{ text }} {{ $t('flight.package') }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>

      <!-- https://discord.com/channels/1054696448110903327/1317046530032668744/1317146360536895571 -->
      <BAlert
        v-if="canBuyBaggageAddOnSourceTH && segment?.segmentData?.source === 'TH'"
        show
        variant="danger"
        class="px-2 py-1 fw-700 rounded-lg my-50"
      >
        <HStack center>
          <div>Lưu ý: Giá hành lý của hãng Scoot có thể thay đổi sau khi mua.</div>
          <div>Vui lòng thông báo đến khách hàng trước khi xác nhận thanh toán.</div>
        </HStack>
      </BAlert>

      <div
        v-if="!isHideFunction"
        class="text-center mt-50"
      >
        <b-button
          class="mr-2"
          variant="outline-warning"
          @click="handleClearForm"
        >
          {{ $t('flight.clear') }}
        </b-button>

        <b-button
          variant="primary"
          @click="handleClickAddButton"
        >
          {{ $t('flight.addService') }}
        </b-button>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BAlert, BButton, BFormGroup, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed,
  toRefs,
} from '@vue/composition-api'
import {
  groupBy, isEmpty, sortBy, cloneDeep,
} from 'lodash-es'

import { resolveSsrBagsName, resolveSsrNameByCode, resolveVariantByBagg } from '@/constants/selectOptions'
import store from '@/store'
import env from '@/libs/env'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'

import useCreateBookingHandle from '@flightv2/reservation/useCreateBookingHandle'
import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BFormGroup,
    BSpinner,
    vSelect,
  },
  props: {
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  /*
  ----- CÁC HÃNG MUA HÀNH LÝ KHI TẠO BOOKING -----
  - MUA THEO HÀNH TRÌNH: QH, VN1A, VJ, VU.
  - MUA THEO CHẶNG: AK, F1, TH.
  - MUA THEO CẢ CHẶNG HOẶC CHẶNG/HÀNH TRÌNH: TR, JQ.
  */
  setup(props) {
    const { toastSuccess, toastWarning, toastError } = useToast()

    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      getAddonsData,
    } = useBookingHandle()
    const {
      isHideFunction,
    } = useCreateBookingHandle()

    const { isEnableFnSourceAKJSON, canBuyBaggageAddOnSourceTH, isDevelopment } = env
    const { isCombination } = toRefs(props)
    const passengersData = ref(null)
    function setBlankDataPassengers() {
      passengersData.value = cloneDeep(props.passengerDataToAdd.filter(passenger => passenger.paxType !== 'INFANT'))
    }
    // Set passenger data
    watch(() => props.passengerDataToAdd, () => {
      setBlankDataPassengers()
    }, { deep: true, immediate: true })

    // SECTION  SERVICE ADDONS
    const serviceOptions = computed(() => store.getters['app-create-booking/getSsrBags'] || [])
    const loading = computed(() => store.getters['app-create-booking/getIsRetryLoading'] || false)

    function getSsrDataBySource(ssrBagLists, tripOrSegment, flightsName) {
      if (['VN1A'].includes(tripOrSegment.source)) {
        const result = ssrBagLists.filter(item => item.segmentIds.map(item => item.split('-')[0]).includes(tripOrSegment.departure.IATACode)
                                   && (isCombination.value ? true : (item?.itineraryId === tripOrSegment.airlineItineraryId)))
        return result
      }

      if (['VJ'].includes(tripOrSegment.source)) {
        const result = ssrBagLists.filter(item => (item.flights === flightsName) && tripOrSegment.segments.map(s => s.segmentId).includes(item.segmentIds[0])) // (item.segmentIds[0] === trip.segments[0].segmentId))
        return result
      }

      if (['TR', 'JQ'].includes(tripOrSegment.source)) {
        const result = ssrBagLists.filter(bagItem => bagItem.flights === tripOrSegment.itemFlight)
        return result
      }

      // if (['JQ'].includes(tripOrSegment.source)) { // Không dùng flights và itineraryId
      //   if (tripOrSegment.isSegment) {
      //     const result = ssrBagLists.filter(bagItem => (bagItem?.segmentIds?.length < 2) && bagItem?.segmentIds?.[0] === tripOrSegment.segmentId)
      //     return result
      //   }
      //   const result = ssrBagLists.filter(bagItem => (bagItem?.segmentIds?.length > 1) && bagItem?.segmentIds?.every(itemSegment => tripOrSegment.segments.some(segment => segment.segmentId === itemSegment)))
      //   return result
      // }

      if (['VU', 'TH'].includes(tripOrSegment.source)) {
        const result = ssrBagLists.filter(item => (item.flights === flightsName) && item.segmentIds.includes(tripOrSegment.segmentId ? tripOrSegment.segmentId : tripOrSegment.segments[0].segmentId))
        return result
      }

      if (['1A'].includes(tripOrSegment.source)) {
        const result = ssrBagLists.filter(item => (item.flights.includes(flightsName)) && item.segmentIds.some(s => tripOrSegment.segments.map(s => s.segmentId).includes(s)))
        return result
      }

      if (['AK'].includes(tripOrSegment.source) && !isEnableFnSourceAKJSON) {
        const filterItemBySegments = ssrBagLists.filter(item => tripOrSegment.segments.map(s => s.segmentId).some(it => item.segmentIds.includes(it)))
        const groupSegmentItems = groupBy(filterItemBySegments, item => item.code)
        const getPrice = (data, field) => data.reduce((total, it) => total + it.fares[0][field], 0)
        const result = []
        for (const key in groupSegmentItems) {
          if (Object.prototype.hasOwnProperty.call(groupSegmentItems, key)) {
            const data = {
              ...groupSegmentItems[key][0],
              flights: flightsName,
              fares: [{
                paxType: 'ALL',
                fee: getPrice(groupSegmentItems[key], 'fee'),
                tax: getPrice(groupSegmentItems[key], 'tax'),
                total: getPrice(groupSegmentItems[key], 'total'),
              }],
              reqSsrList: groupSegmentItems[key].map(item => ({
                segmentId: item.segmentIds[0],
                fares: item.fares,
              })),
            }
            result.push(data)
          }
        }
        return result
      }

      return ssrBagLists.filter(item => item.flights === flightsName)
    }

    const uniqueByFlights = array => {
      const map = new Map()
      return array.filter(item => {
        const key = JSON.stringify(item.flights)
        if (!map.has(key)) {
          map.set(key, true)
          return true
        }
        return false
      })
    }

    // SECTION SEGMENT
    const segment = ref(null)
    const segmentOptions = ref([])
    const disableServicePackage = computed(() => !segment.value)

    watch(() => serviceOptions.value, serviceOptionsValue => {
      segment.value = null
      segmentOptions.value = []
      setBlankDataPassengers()

      if (!isEmpty(serviceOptionsValue)) {
        let flightOrSegmentLists = []

        props.selectedTrip.forEach((trip, tripIndex) => {
          if (['TR', 'JQ'].includes(trip.source)) { // TR thêm hành trình vào list, result cả chặng và hành trình
            const listSsrInTrip = serviceOptionsValue.filter(item => item.segmentIds.every(itemSegmentId => trip.segments.some(segment => segment.segmentId === itemSegmentId)))
            const listSsrUnique = uniqueByFlights(listSsrInTrip)

            listSsrUnique.forEach(item => {
              flightOrSegmentLists.push({ ...trip, itemFlight: item.flights, segments: trip.segments.filter(segment => item.segmentIds.includes(segment.segmentId)) })
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            // if (['AK'].includes(trip.source) && !isEnableFnSourceAKJSON) { // bỏ TH: do long2k gộp chặng vào (https://discord.com/channels/1054696448110903327/1055496072857919529/1286024508582662294)
            //   trip.segments.forEach(segment => {
            //     flightOrSegmentLists.push({
            //       ...segment,
            //       source: trip.source,
            //       isSegment: true,
            //       itineraryId: trip.itineraryId ? trip.itineraryId.toString() : null,
            //     }) // Theo chặng
            //   })
            // } else {
            const tripData = !isCombination.value ? trip : {
              ...trip,
              tripId: tripIndex + 1,
            }
            flightOrSegmentLists.push(tripData) // Theo hành trình
            // }
          }
        })

        // NOTE: VN1A bỏ qua các hành trình có điểm dừng  https://discord.com/channels/1054696448110903327/1315896234463985694/1318860064261537793
        // VN1A tạm ẩn các hành trình có điểm dừng
        if (!isDevelopment && props.selectedTrip.some(trip => ['VN1A'].includes(trip.source)) && !isEmpty(flightOrSegmentLists)) {
          flightOrSegmentLists = flightOrSegmentLists.filter(trip => trip?.segments?.length < 2)
        }

        flightOrSegmentLists.forEach((dataFlight, index) => { // data ở đây có thể là hành trình hoặc chặng
          const flightsName = ['1A', 'TR', 'JQ'].includes(dataFlight?.source ?? '')
            ? dataFlight.segments.map(s => `${s.departure.IATACode}-${s.arrival.IATACode}`).join(' | ')
            : `${dataFlight.departure.IATACode}-${dataFlight.arrival.IATACode}`

          const mappedAddonsData = {
            segmentData: {
              ...dataFlight,
              segmentIndex: index + 1,
              flights: flightsName,
            },
            ssrData: getSsrDataBySource(serviceOptionsValue, dataFlight, flightsName),
          }

          if (mappedAddonsData.ssrData.length > 0) {
            segmentOptions.value.push(mappedAddonsData)
          }
        })
      }
    }, { deep: true, immediate: true })

    // SECTION ADDONS OPTION
    const serviceListOptions = ref([])
    const amountOptions = ref([])

    watch(() => segment.value, segmentValue => {
      amountOptions.value = []
      serviceListOptions.value = []
      setBlankDataPassengers()

      if (segmentValue) {
        const data = segmentValue.ssrData.map(item => ({
          segmentData: segmentValue.segmentData,
          ...item,
        }))

        if (!isEmpty(data)) {
          serviceListOptions.value = data.sort((a, b) => (a?.details[0]?.weight || 1) - (b?.details[0]?.weight || 0))
          if (!isEmpty(serviceListOptions.value) && serviceListOptions.value.some(i => ['VJ', 'VZ'].includes(i.airline))) {
            serviceListOptions.value = sortBy(serviceListOptions.value, bagItem => bagItem.code)
          }
        }

        if (['QH'].includes(data[0]?.segmentData?.source) && !isEmpty(data)) {
          const option = [1, 2, 3, 4, 5, 6]
          amountOptions.value.push(...option)
        } else if (['VN1A', 'VN'].includes(data[0]?.segmentData?.source) && !isEmpty(data)) {
          const option = Array(15).fill(1).map((n, i) => i + 1)
          amountOptions.value.push(...option)
        } else {
          amountOptions.value.push(1)
        }
      }
    }, { deep: true })

    function resolvePriceService(service, paxType) {
      const fare = service.fares.find(fare => fare.paxType === paxType) || service.fares[0]
      return fare.total || 0
    }

    // handle set setDefaultPassengerAmount
    const setDefaultPassengerAmount = (val, passenger) => {
      passenger.serviceAmount = val !== null ? 1 : null
    }

    // handle clear form
    function handleClearForm() {
      segment.value = null
      // serviceSelected.value = null
      setBlankDataPassengers()
    }

    // ANCHOR - HANDLE ADD-ONS --- Add service
    function addDataToStore(ssrsToAdd) {
      for (const ssrData of ssrsToAdd) {
        const rawData = cloneDeep(getAddonsData.value)

        // ANCHOR - Thêm số lượng gói
        if (['QH', 'VN1A', 'VN'].includes(ssrData.source ?? ssrData.airline)) {
          const existingSsrIndex = getAddonsData.value.findIndex(ssr => ssr.paxId === ssrData.paxId && ssr.ssrId === ssrData.ssrId)

          if (existingSsrIndex === -1) {
            rawData.push(ssrData)
          } else {
            // const totalAmount = rawData[existingSsrIndex].amount + ssrData.amount
            // if (totalAmount > 1) {
            //   toastError({
            //     title: 'Không thể thêm số lượng quá 1 gói hành lý / hành trình!',
            //   })
            //   return
            // }

            rawData[existingSsrIndex].amount += ssrData.amount
          }
        } else {
          rawData.push(ssrData)
        }

        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setAddonsSelectData`, rawData)
        handleClearForm()
      }

      toastSuccess({ title: 'Thêm dịch vụ thành công!' })
    }

    function findExistingPax(paxSsr, isCabinExtra) {
      const existItem = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId
                                                     && ssr.flights === paxSsr.flights
                                                     && ssr.ssrName === paxSsr.ssrName
                                                    && (isCabinExtra ? ssr.code === 'Cabin Extra 2k' : ssr.code !== 'Cabin Extra 2k'))
      return existItem
    }

    function handleClickAddButton() {
      const paxWithDataToAdd = passengersData.value.filter(pax => pax.servicePackage && pax.serviceAmount)

      if (!segment.value || isEmpty(paxWithDataToAdd)) {
        toastWarning({ title: 'Vui lòng chọn gói dịch vụ!' })
        return
      }

      const ssrItemLists = []
      paxWithDataToAdd.forEach(pax => {
        // NOTE: `servicePackage` là data ssrItem của từng passenger để v-model cho field chọn dịch vụ từ v-select (option: serviceListOptions)
        const {
          servicePackage, paxId, serviceAmount, lastName, firstName, paxType,
        } = pax

        const ssrItem = {
          // ========== Data Create Booking ==========
          code: servicePackage.code,
          type: '',
          name: '',
          ...(servicePackage.details && !isEmpty(servicePackage.details[0]) && {
            details: servicePackage.details.map(details => ({
              weight: String(details.weight),
              unit: details.unit,
            })),
          }),
          ...(['VJ', 'VZ', 'VN', '1A', 'VN1A'].includes(servicePackage.source ?? servicePackage.airline) && { itineraryId: servicePackage.segmentData.airlineItineraryId ?? '' }),
          ...(['TR', 'JQ'].includes(servicePackage.source) && {
            itineraryId: servicePackage.itineraryId ?? '',
            segmentIds: servicePackage?.segmentIds ?? [], // dùng so sánh và lấy chặng với case TR
          }),
          ...(['TH', 'AK'].includes(servicePackage.source ?? servicePackage.airline) && { itineraryId: servicePackage.segmentData?.itineraryId || null }),
          ...(['VU'].includes(servicePackage.airline) && { itineraryId: servicePackage.segmentData.segments[0].segmentValue }),
          segmentId: ['VN', 'VN1A'].includes(servicePackage.source ?? servicePackage.airline)
            ? (servicePackage.segmentData.segments[0]?.airlineSegmentId?.toString() || '')
            : ['TR', 'JQ'].includes(servicePackage.source ?? servicePackage.airline)
              ? servicePackage?.segmentIds?.join(' | ')
              : isEmpty(servicePackage.segmentIds)
                ? ''
                : servicePackage.segmentIds[0], // NOTE segmentIds từ api list-ancillary
          paxId,
          addonValue: (['VJ', 'VZ', 'VN', 'VN1A', '1A', 'TR', 'JQ', 'TH'].includes(servicePackage.source ?? servicePackage.airline) || (['AK'].includes(servicePackage.source ?? servicePackage.airline) && isEnableFnSourceAKJSON))
            ? servicePackage.ssrValue
            : resolvePriceService(servicePackage, paxType),

          // ========== Others ==========
          paxType,
          ssrName: servicePackage.ssrName, // toCheck
          ssrId: servicePackage.ssrId, // toCheck

          // ========== Data Show Price ==========
          // HÀNH KHÁCH
          firstName,
          lastName,

          // CHẶNG BAY
          flights: servicePackage.airline === 'QH' ? servicePackage.segmentData.flights : servicePackage.flights,
          tripId: servicePackage.segmentData?.tripId ?? servicePackage.segmentData?.segments[0]?.tripId ?? '',

          // HÃNG HÀNG KHÔNG
          airline: servicePackage.airline,

          // GIÁ
          addonPrice: resolvePriceService(servicePackage, paxType),
          amount: serviceAmount,

          // DỊCH VỤ
          serviceName: servicePackage.details
            ? resolveSsrBagsName(servicePackage)
            : resolveSsrNameByCode(servicePackage.code),

          source: servicePackage.source,

          ...(['AK'].includes(servicePackage.source) && !isEnableFnSourceAKJSON && { reqSsrList: servicePackage.reqSsrList }),
        }
        ssrItemLists.push(ssrItem)
      })

      const existingPaxSsrs = ssrItemLists.filter(paxSsr => {
        let existingPax
        let errTitle

        if (['VN1A', 'VN'].includes(paxSsr.source ?? paxSsr.airline)) {
          const totalExistAmount = getAddonsData.value.find(it => it.ssrName === 'ssrBags'
                                                          && it.paxId === paxSsr.paxId
                                                          && it.flights === paxSsr.flights
                                                          && it.itineraryId === paxSsr.itineraryId)?.amount || 0

          if (totalExistAmount + paxSsr.amount > 15) {
            existingPax = paxSsr
            errTitle = `Hành khách ${paxSsr.firstName ? `${paxSsr.lastName} ${paxSsr.firstName} ` : `${paxSsr.paxType} ${paxSsr.paxId}`} chỉ được thêm tối đa 15 gói hành lý cho từng hành trình!`
          }
        } else if (['VJ', 'VZ'].includes(paxSsr.source ?? paxSsr.airline)) {
          const isCabinExtra = paxSsr.code === 'Cabin Extra 2k'
          existingPax = findExistingPax(paxSsr, isCabinExtra)
        } else if (['TH'].includes(paxSsr.source)) {
          // handle TH mua 1 gói / chặng
          existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.flights === paxSsr.flights)
        } else if (['TR'].includes(paxSsr.source)) {
          // NOTE: TR Thêm từng segment(cả chặng ẩn) thêm được nhiều gói / 1 chặng nhưng mỗi gói chỉ thêm được 1 lần (sl:1); nếu đã thêm gói 1BBG(FLYBAG 20kg) thì ko thêm được gói BG20(20kg) trên chặng đó nữa.
          existingPax = getAddonsData.value.find(addedBagItem => addedBagItem.paxId === paxSsr.paxId && ['ssrBags'].includes(addedBagItem.ssrName) && (addedBagItem.segmentIds.some(segmentAdded => paxSsr.segmentIds.includes(segmentAdded)) && ['1BBG', 'BG20'].every(code => [addedBagItem.code, paxSsr.code].includes(code) || addedBagItem.code === paxSsr.code)))

          if (existingPax) {
            errTitle = `Hành khách ${paxSsr.firstName ? `${paxSsr.lastName} ${paxSsr.firstName} ` : `${paxSsr.paxType} ${paxSsr.paxId}`} đã có gói '${paxSsr.serviceName}' ở chặng này, không thể mua thêm!`
          }
        } else if (['JQ'].includes(paxSsr.source)) {
          const totalExistKg = getAddonsData.value
            .filter(it => it.ssrName === 'ssrBags'
                       && it.paxId === paxSsr.paxId
                       && it.flights === paxSsr.flights
                       && it.itineraryId === paxSsr.itineraryId)
            .reduce((total, item) => {
              const weight = item?.details?.[0]?.weight
              const result = total + (!Number.isNaN(Number(weight)) ? (Number(weight) * item.amount) : 0)
              return result
            }, 0)

          const newKg = Number(paxSsr?.details?.[0]?.weight)
          existingPax = totalExistKg + newKg > 40 ? paxSsr : undefined
          if (existingPax) {
            errTitle = `Hành khách ${paxSsr.firstName ? `${paxSsr.lastName} ${paxSsr.firstName} ` : `${paxSsr.paxType} ${paxSsr.paxId}`} chỉ được mua thêm tối đa 40KG hành lý cho từng hành trình!`
          }
        } else if (['QH'].includes(paxSsr.source ?? paxSsr.airline)) {
          const newKg = Number(paxSsr?.details?.[0]?.weight) * paxSsr.amount
          if (!newKg) {
            existingPax = paxSsr
            errTitle = `Hành khách ${paxSsr.firstName ? `${paxSsr.lastName} ${paxSsr.firstName} ` : `${paxSsr.paxType} ${paxSsr.paxId}`} chỉ được thêm tối đa 60KG gói hành lý cho từng hành trình!`
            toastWarning({ title: 'Lỗi thêm gói dịch vụ, vui lòng thực hiện lại hoặc liên hệ booker để xử lý!' })
          }

          const totalExistKg = getAddonsData.value
            .filter(it => it.ssrName === 'ssrBags'
                       && it.paxId === paxSsr.paxId
                       && it.flights === paxSsr.flights
                       && it.itineraryId === paxSsr.itineraryId)
            .reduce((total, item) => {
              const weight = item?.details?.[0]?.weight
              const result = total + (!Number.isNaN(Number(weight)) ? (Number(weight) * item.amount) : 0)
              return result
            }, 0)

          existingPax = totalExistKg + Number(newKg) > 60 ? paxSsr : undefined
        } else {
          existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.ssrId === paxSsr.ssrId)
        }

        if (existingPax) {
          const data = existingPax

          // tên gói hiển thị trên toast
          // với TH thì chỉ mua 1 gói / chặng
          const ssrNameToast = ['TH'].includes(existingPax.source) ? 'hành lý' : resolveSsrNameByCode(data.code) || data.code

          const addOnlyOneSsr = `${data.firstName
            ? `${data.lastName} ${data.firstName} `
            : `${data.paxType} ${data.paxId}`
          }  chỉ có thể mua một gói ${ssrNameToast} trên một chặng/hành trình.`

          toastError({
            title: ['QH', 'VN1A', 'VN', 'TR', 'JQ'].includes(paxSsr.source ?? paxSsr.airline) ? errTitle : addOnlyOneSsr,
          })

          return data
        }

        return existingPax
      })

      if (existingPaxSsrs.length === 0) {
        addDataToStore(ssrItemLists)
      }
    }

    function filterBy(option, label, search) {
      const code = (option?.code ?? '').toUpperCase()
      const searchUpper = (search ?? '').toUpperCase()
      const detailsUpper = (`${option?.details[0]?.weight}` ?? '').toUpperCase()
      return code?.includes(searchUpper) || detailsUpper.includes(searchUpper)
    }

    return {
      handleClickAddButton,
      handleClearForm,
      setDefaultPassengerAmount,
      resolveSsrNameByCode,
      resolveSsrBagsName,
      resolvePriceService,

      serviceOptions,
      segmentOptions,
      serviceListOptions,
      amountOptions,
      passengersData,
      segment,

      disableServicePackage,
      formatCurrency,

      isEmpty,
      resolveVariantByBagg,
      convertISODateTime,
      filterBy,
      loading,
      isHideFunction,
      canBuyBaggageAddOnSourceTH,
      isDevelopment,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style lang="scss" scoped>
  .ssrs_package_select {
    ::v-deep .vs__dropdown-option {
      text-wrap: wrap !important;
    }

    ::v-deep .vs__dropdown-option--selected {
      color: black !important;
      &.vs__dropdown-option--highlight {
      color: black !important;
    }
    }
  }
  </style>
